


































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import _ from 'lodash'
import moment from 'moment'

import MyCommon from '@/mixins/common'

@Component
export default class extends Mixins(MyCommon) {
  @Prop() stadiumId!: number

  dates: any[] = []

  curdate = ''

  allowHalfAnHour = false

  timeCosts: any[] = []

  renderWeek(week: number) {
    if (week === 7) {
      week = 0
    }
    const obj: any = _.find(this.weeks, (o) => o.key == week)

    return obj.name
  }

  changeDate(d: string) {
    this.curdate = d

    this.loadData()
  }

  async loadData() {
    const res = await this.$store.dispatch('stadium/dailySituationInfo', {
      stadium_id: this.stadiumId,
      date: this.curdate,
    })
    // console.log(res);
    if (res && res.Data) {
      this.timeCosts = res.Data
    }
  }

  renderAvailableCount(hour: number, minute: string) {
    return _.get(this.timeCosts, [`${hour}:${minute}`, 'availableTimeCost'])
  }

  async loadStadiumSettings() {
    const res = await this.$store.dispatch('stadium/settingDetail', {
      stadium_id: this.stadiumId,
    })
    if (res && res.Data) {
      this.allowHalfAnHour = res.Data.is_half_time === '1'
    }
  }

  mounted() {
    this.loadStadiumSettings()

    this.curdate = moment().format('YYYY-MM-DD')
    const arr: number[] = _.range(1, 8)
    // let dates: any[] = [];
    this.dates.push({
      date: moment().format('YYYY-MM-DD'),
      title: moment().format('MM-DD'),
      week: '今天',
    })
    // console.log(arr);
    for (let i = 1; i <= arr.length; i++) {
      const t: any = moment().add(i, 'day')
      this.dates.push({
        date: t.format('YYYY-MM-DD'),
        title: t.format('MM-DD'),
        week: this.renderWeek(t.isoWeekday()),
      })
    }

    // console.log(this.dates);
    this.loadData()
  }
}
