























































































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvBorder9 from '$ui/dv/packages/my-dv-border9'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvHeader2 from '$ui/dv/packages/my-dv-header2'

// @ts-ignore
import MyDvText from '$ui/dv/packages/my-dv-text'

import MyCommon from '@/mixins/common'

import MyBox from '@/components/box.vue'

import Amap from '@amap/amap-vue/lib/amap'

import MessageScroll from '@/components/message_scroll.vue'

import TodayGround from './components/today_ground.vue'

@Component({
  components: {
    MyDvBox,
    MyDvBorder9,
    MyDvTitle,
    MyDvHeader2,
    MyDvText,
    Amap,
    MyBox,
    MessageScroll,
    TodayGround,
  },
})
export default class extends Mixins(MyCommon) {
  mode: string = ''

  stadiumId: number = 0

  formData: any = {}

  changeMode(mode: string) {
    this.mode = mode
  }

  mounted() {
    // console.log(this.$route)
    const query: any = this.$route.query
    if (query) {
      if (query.id) {
        this.stadiumId = query.id
      }
    }

    this.loadStadiumDetail()
  }

  async loadStadiumDetail() {
    const res = await this.$store.dispatch('stadium/detail', {
      stadium_id: this.stadiumId,
    })

    // console.log(res)
    if (res && res.Data) {
      this.formData = res.Data

      this.setMapCenter()
    }
  }

  // 设置
  setMapCenter() {
    // @ts-ignore
    const map = this.$refs.myMap.$map

    const lnglat: any = new window.AMap.LngLat(
      Number(this.formData.stadium_info.longitude),
      Number(this.formData.stadium_info.latitude)
    )

    const marker = new window.AMap.Marker({
      position: lnglat,
      title: this.formData.stadium_info.name,
    })

    map.add(marker)

    map.setZoomAndCenter(18, [
      this.formData.stadium_info.longitude,
      this.formData.stadium_info.latitude,
    ])
  }
}
